<template>
  <v-row v-if="videos.length">
    <v-col v-for="video in videos" :key="video.id" cols="12" sm="6" md="4">
      <v-card @click="() => openVideo(video.id)">
        <v-img
          v-if="video.snippet && video.snippet.thumbnails && video.snippet.thumbnails.medium"
          :src="video.snippet.thumbnails.medium.url"
          :alt="video.snippet.title"
          height="200px"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
          <!-- Badge overlay per la modalità griglia -->
          <v-chip
            v-if="video.badge"
            class="badge-overlay"
            color="white"
            text-color="black"
            style="background-color: rgba(255, 255, 255, 0.8); font-size: 18px;"
          >
            {{ video.badge }}
          </v-chip>
          <!-- Durata del video in basso a destra -->
          <div class="video-duration">{{ video.duration }}</div>
        </v-img>
        <v-card-title>{{ video.snippet?.title }}</v-card-title>
        <v-card-subtitle>
          {{ video.snippet?.channelTitle }} ({{ video.subscribers }} subscribers) - Views: {{ video.views }}
        </v-card-subtitle>
        <v-card-subtitle>{{ video.publishedAt }} ({{ video.daysSincePublished }} days ago)</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col>
              <v-chip color="blue lighten-2">
                Views/Subs: {{ video.viewsPerSubscriber }}
              </v-chip>
            </v-col>
            <v-col>
              <v-chip color="red lighten-2">
                Multiplier: {{ video.viewDifference }}
              </v-chip>
            </v-col>
            <v-col>
              <v-chip color="orange lighten-2">
                Hype: {{ video.hype }}
              </v-chip>
            </v-col>
            <v-col>
              <v-chip v-if="video.cached" color="green lighten-2">
                Cached
              </v-chip> 
              <v-chip v-else color="red lighten-2">
                New
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';

export default {
  name: 'VideoGrid',
  props: {
    videos: {
      type: Array,
      required: true
    }
  },
  methods: {
    async openVideo(videoId) {
      console.log('Opening video with ID:', videoId);
      if (videoId) {
        const isShort = await this.isYouTubeShort(videoId);
        const url = isShort
          ? `https://www.youtube.com/shorts/${videoId}`
          : `https://www.youtube.com/watch?v=${videoId}`;
        window.open(url, '_blank');
      } else {
        console.error('Video ID is undefined');
      }
    },
    async isYouTubeShort(videoId) {
      const url = `https://www.youtube.com/shorts/${videoId}`;
      try {
        const response = await axios.head(url);
        return response.status === 200; // Status 200 indicates it's a Short
      } catch (error) {
        if (error.response && error.response.status === 303) {
          return false; // Status 303 indicates a redirect to the standard watch URL
        }
        return false; // Default to false if any other error occurs
      }
    }
  },
  created() {
    console.log('VideoGrid videos:', this.videos);
  }
}
</script>

<style scoped>
.badge-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
}
.video-duration {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 2px 4px;
  border-radius: 2px;
  font-size: 14px;
}
</style>
