<template>
  <v-row>
    <v-col cols="12" md="8" offset-md="2">
      <br><br>
      <h2>
        What video do you want to create?</h2>
      <v-text-field
        v-model="query"
        label="Search for YouTube videos"
        @keyup.enter="onSearch"
        outlined
        clearable
        class="mb-4"
      ></v-text-field>
      <v-btn @click="onSearch" color="primary">Search</v-btn>
      <!-- <v-btn v-if="query" @click="toggleVideoType" color="secondary" class="ml-2">
        {{ videoType === 'short' ? 'Show Long Videos' : 'Show Short Videos' }}
      </v-btn> -->
      <v-row class="mt-4">
        <v-col cols="12" md="6">
          <v-checkbox
            v-model="localShowOnlyRecent"
            label="Show only recent videos"
            @change="updateShowOnlyRecent"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model.number="localMaxResults"
            label="Max Results"
            type="number"
            @change="updateMaxResults"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-chip-group class="mt-4">
        <v-chip
          v-for="keyword in relatedKeywords"
          :key="keyword"
          @click="onKeywordClick(keyword)"
        >
          {{ keyword }}
        </v-chip>
      </v-chip-group>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    maxResults: {
      type: Number,
      default: 3
    },
    showOnlyRecent: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      query: '',
      relatedKeywords: [],
      videoType: 'long',
      localShowOnlyRecent: this.showOnlyRecent,
      localMaxResults: this.maxResults,
      apiKeys: [
        'AIzaSyCH_zIe3qA0M6EdrvaDyFYAJ-0m9Xqoykk',
        'AIzaSyAy61teDSTTQAKuMiUMNqA5gSzoGP2v_jE',
        'AIzaSyCI0v-iu5ACBCq3fhrE8cpHt2LKsI6T-6E',
        'AIzaSyBk41VAyOTPlW5u35ONrjDO567_VzsjrRg',
        'AIzaSyD4s0h-v6kCU9FW7QZIiL7K7uXviuvTJLI',
        'AIzaSyC_bJtpr05WbNb2AzgqZQxOJfeTxg3zccY',
        'AIzaSyA1qRqRumusHeoJl3KRcUN6eb-bXFv9bms'
      ],
      currentApiKeyIndex: 0
    }
  },
 methods: {
  async onSearch() {
    if (!this.query) return;
    this.$emit('search', this.query, this.videoType);
    await this.fetchRelatedKeywords(this.query);
  },
  async fetchRelatedKeywords(query) {
    const url = `https://www.googleapis.com/youtube/v3/search?part=snippet&type=video&maxResults=5&q=${query}&key=${this.apiKeys[this.currentApiKeyIndex]}`;
    try {
      const response = await axios.get(url);
      this.relatedKeywords = response.data.items.map(item => item.snippet.title);
    } catch (error) {
      console.error('Error fetching related keywords:', error);
      this.rotateApiKey();
    }
  },
  rotateApiKey() {
    this.currentApiKeyIndex = (this.currentApiKeyIndex + 1) % this.apiKeys.length;
  },
  onKeywordClick(keyword) {
    this.query = keyword;
    this.onSearch();
  },
  toggleVideoType() {
    this.videoType = this.videoType === 'long' ? 'short' : 'long';
    this.$emit('toggle-video-type', this.videoType);
    this.onSearch(); // Ri-esegue la ricerca con il nuovo tipo di video
  },
  updateShowOnlyRecent() {
    this.$emit('update-show-only-recent', this.localShowOnlyRecent);
    this.onSearch(); // Ri-esegue la ricerca con il nuovo filtro
  },
  updateMaxResults() {
    this.$emit('update-max-results', this.localMaxResults);
    this.onSearch(); // Ri-esegue la ricerca con il nuovo numero massimo di risultati
  }
}

}
</script>

<style scoped>
.v-checkbox {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.v-text-field {
  margin-top: 10px;
}
</style>
