<template>
  <v-app>
    <v-navigation-drawer app>
      <v-list>
        <v-list-item align="center">
          <a href ="/">
          <v-img src="@/assets/logo.png" alt="Logo" width="120" height="120"></v-img></a>
          <v-list-item-title class="title"></v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-if="!user">
          <!-- <UserLogin @user-logged-in="handleUserLoggedIn" /> -->
        </v-list-item>
        <v-list-item v-else>
          <!-- <UserProfile :user="user" @user-logged-out="handleUserLoggedOut" /> -->
        </v-list-item>
        <v-list-item align="center">
          <v-btn @click="clearCache" color="red">Clear Cache</v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-toolbar-title>DodoTag</v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="development-message">
        ⚠️ This app is still under development. Features may change or be unstable.
      </div>
      <v-spacer></v-spacer>
      <!-- <v-btn @click="toggleFiltersDialog">Search Filters</v-btn> -->
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <v-container class="fill-height">
        <v-row>
          <v-col>
            <!-- Passa i parametri come props -->
            <SearchBar
              :query="query"
              :video-type="videoType"
              :max-results="maxResults"
              :show-only-recent="showOnlyRecent"
              @search="searchVideos"
              @toggle-video-type="toggleVideoType"
              @update-show-only-recent="updateShowOnlyRecent"
              @update-max-results="updateMaxResults"
            />
            <div v-if="searchPerformed" class="d-flex justify-space-between align-center mb-4">
              <h2>Results</h2>
              <v-btn @click="toggleView">{{ viewMode === 'grid' ? 'List View' : 'Grid View' }}</v-btn>
            </div>
            <VideoGrid v-if="viewMode === 'grid' && searchPerformed" :videos="uniqueSortedVideos" />
            <VideoList v-else-if="searchPerformed" :videos="uniqueSortedVideos" />
            <DodoVideos v-if="dodoVideos.length > 0" :videos="dodoVideos" @exact-search="handleExactSearch" />
            <v-btn v-if="searchPerformed && nextPageToken" @click="loadMoreVideos" color="primary" class="mt-4">Load More</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    
    <SearchFilters v-model:dialog="filtersDialog" :filters="filters" @apply-filters="searchWithFilters" />
  </v-app>
</template>

<script>
import {
  VApp,
  VNavigationDrawer,
  VList,
  VListItem,
  VListItemTitle,
  VDivider,
  VAppBar,
  VToolbarTitle,
  VMain,
  VContainer,
  VRow,
  VCol,
  VBtn,
  VImg
} from 'vuetify/components'
import SearchBar from './components/SearchBar.vue'
import VideoGrid from './components/VideoGrid.vue'
import VideoList from './components/VideoList.vue'
// import UserLogin from './components/UserLogin.vue'
// import UserProfile from './components/UserProfile.vue'
import SearchFilters from './components/SearchFilters.vue'
import DodoVideos from './components/DodoVideos.vue'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    VApp,
    VNavigationDrawer,
    VList,
    VListItem,
    VListItemTitle,
    VDivider,
    VAppBar,
    VToolbarTitle,
    VMain,
    VContainer,
    VRow,
    VCol,
    VBtn,
    VImg,
    SearchBar,
    VideoGrid,
    VideoList,
    // UserLogin,
    // UserProfile,
    SearchFilters,
    DodoVideos
  },
  data() {
    return {
      videosData: [],
      user: null,
      viewMode: 'grid',
      maxResults: 6,
      showOnlyRecent: true,
      filtersDialog: false,
      filters: {
        multiplier: [1, 500],
        views: [1000, 1000000000],
        subscribers: [900, 250000000],
        duration: [60, 25200],
        viewsSubs: [0.1, 500],
        comments: [1, 10000000],
        publicationDate: 'All time',
      },
      apiKeys: [
        'AIzaSyAy61teDSTTQAKuMiUMNqA5gSzoGP2v_jE',
        'AIzaSyCI0v-iu5ACBCq3fhrE8cpHt2LKsI6T-6E',
        'AIzaSyBk41VAyOTPlW5u35ONrjDO567_VzsjrRg',
        'AIzaSyD4s0h-v6kCU9FW7QZIiL7K7uXviuvTJLI',
        'AIzaSyC_bJtpr05WbNb2AzgqZQxOJfeTxg3zccY',
        'AIzaSyA1qRqRumusHeoJl3KRcUN6eb-bXFv9bms',
        'AIzaSyCH_zIe3qA0M6EdrvaDyFYAJ-0m9Xqoykk'
      ],
      currentApiKeyIndex: 0,
      nextPageToken: '',
      searchPerformed: false,
      loadedVideoIds: new Set(),
      videoType: 'long',
      query: '',
      videoCache: {}, // cache for storing videos
      cacheTTL: 300000 // TTL per cache in millisecondi (5 minuti)
    }
  },
  created() {
    // Recupera la cache da localStorage quando l'app viene caricata
    const cachedData = localStorage.getItem('videoCache');
    if (cachedData) {
      this.videoCache = JSON.parse(cachedData);
      console.log('Cache loaded from localStorage:', this.videoCache);
    }

    // Leggi i parametri di ricerca dall'URL
    this.loadSearchFromQuery(this.$route.query);
  },
  watch: {
    // Osserva i cambiamenti nella route
    '$route.query': function (newQuery) {
      console.log('Route query changed:', newQuery);
      this.loadSearchFromQuery(newQuery);
    }
  },
  computed: {
    uniqueSortedVideos() {
      const uniqueVideos = [...new Map(this.videosData.map(video => [video.id, video])).values()];
      uniqueVideos.sort((a, b) => {
        if (a.badge && !b.badge) return -1
        if (!a.badge && b.badge) return 1
        const diff = parseFloat(b.viewsPerSubscriber) - parseFloat(a.viewsPerSubscriber)
        if (diff !== 0) return diff
        return parseFloat(b.viewDifference) - parseFloat(a.viewDifference)
      });
      console.log('Unique sorted videos:', uniqueVideos);
      return uniqueVideos;
    },
    dodoVideos() {
      const dodoVids = this.uniqueSortedVideos.filter(video => video.badge && video.badge.includes('🦤'));
      console.log('Dodo videos:', dodoVids);
      return dodoVids;
    }
  },
  methods: {
    toggleView() {
      this.viewMode = this.viewMode === 'grid' ? 'list' : 'grid';
      console.log('View mode toggled:', this.viewMode);
    },
    toggleVideoType() {
      this.videoType = this.videoType === 'long' ? 'short' : 'long';
      console.log('Video type toggled:', this.videoType);
      this.searchVideos(this.query);
    },
    updateShowOnlyRecent(value) {
      this.showOnlyRecent = value;
      console.log('Show only recent toggled:', this.showOnlyRecent);
      this.searchVideos(this.query);
    },
    updateMaxResults(value) {
      this.maxResults = value;
      console.log('Max results updated:', this.maxResults);
      this.searchVideos(this.query);
    },
    toggleFiltersDialog() {
      this.filtersDialog = !this.filtersDialog;
      console.log('Filters dialog toggled:', this.filtersDialog);
    },
    searchWithFilters(newFilters) {
      this.filters = newFilters;
      console.log('Filters applied:', this.filters);
      this.searchVideos(this.query);
    },
    searchVideos(query, videoType = this.videoType) {
      if (!query) return;
      this.query = query;
      this.videosData = [];
      this.loadedVideoIds.clear();
      this.nextPageToken = ''; // Reset nextPageToken per una nuova ricerca
      this.searchPerformed = true;

      // Aggiungi i parametri di ricerca all'URL
      this.$router.push({
        path: '/',
        query: {
          query: this.query,
          videoType: this.videoType,
          maxResults: this.maxResults,
          showOnlyRecent: this.showOnlyRecent,
        }
      });

      const cachedResults = this.videoCache[query] && Date.now() - this.videoCache[query].timestamp < this.cacheTTL;

      if (cachedResults && this.videoCache[query].videos.length >= this.maxResults) {
        console.log('Using cached results for query:', query);
        this.videosData = this.videoCache[query].videos.slice(0, this.maxResults).map(video => ({
          ...video,
          cached: true
        }));
        this.nextPageToken = this.videoCache[query].nextPageToken || '';
      } else {
        if (cachedResults) {
          console.log('Using partial cached results for query:', query);
          this.videosData = this.videoCache[query].videos.map(video => ({
            ...video,
            cached: true
          }));
          this.nextPageToken = this.videoCache[query].nextPageToken || '';
        }
        console.log('Fetching new results for query:', query);
        this.fetchVideos(query, videoType);
      }
    },

    async fetchVideos(query, videoType) {
        const currentDate = new Date();
        const oneYearAgo = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1)).toISOString();
        const recentFilter = this.showOnlyRecent ? `&publishedAfter=${oneYearAgo}` : '';
        const maxResultsFilter = `&maxResults=${this.maxResults}`;
        const pageTokenFilter = this.nextPageToken ? `&pageToken=${this.nextPageToken}` : '';
        const url = `https://www.googleapis.com/youtube/v3/search?part=snippet&type=video${maxResultsFilter}${recentFilter}${pageTokenFilter}&q=${query}&key=${this.apiKeys[this.currentApiKeyIndex]}`;

        try {
            const searchResponse = await axios.get(url);
            console.log('API response received:', searchResponse);
            const videoIds = searchResponse.data.items.map(item => item.id.videoId).join(',');
            this.nextPageToken = searchResponse.data.nextPageToken || '';

            const videoDetailsUrl = `https://www.googleapis.com/youtube/v3/videos?part=contentDetails,statistics,snippet&id=${videoIds}&key=${this.apiKeys[this.currentApiKeyIndex]}`;
            const videoDetailsResponse = await axios.get(videoDetailsUrl);

            const videosWithDetails = await Promise.all(videoDetailsResponse.data.items.map(async (video) => {
                if (this.loadedVideoIds.has(video.id)) {
                    console.log('Duplicate video skipped:', video.id);
                    return null;
                }

                const duration = video.contentDetails.duration;
                const durationSeconds = this.parseDuration(duration);
                const durationFormatted = this.formatDuration(durationSeconds);

                if (videoType === 'short' && durationSeconds > 60) {
                    console.log('Short video skipped:', video.snippet.title);
                    return null;
                }
                if (videoType === 'long' && durationSeconds <= 60) {
                    console.log('Long video skipped:', video.snippet.title);
                    return null;
                }

                const channelId = video.snippet.channelId;
                const channelUrl = `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${channelId}&key=${this.apiKeys[this.currentApiKeyIndex]}`;
                const channelResponse = await axios.get(channelUrl);
                const channelStats = channelResponse.data.items[0].statistics;

                const averageViews = channelStats.viewCount / channelStats.videoCount;
                const viewsPerSubscriber = video.statistics.viewCount / channelStats.subscriberCount;
                const viewDifference = (video.statistics.viewCount / averageViews).toFixed(2);
                const performanceMultiplier = (parseFloat(viewDifference) + 1).toFixed(2) + 'x';
                const hype = (parseFloat(performanceMultiplier) * viewsPerSubscriber).toFixed(2);
                const badge = this.getBadge(performanceMultiplier, viewsPerSubscriber);
                const publishedAt = new Date(video.snippet.publishedAt).toLocaleDateString();
                const daysSincePublished = Math.floor((new Date() - new Date(video.snippet.publishedAt)) / (1000 * 60 * 60 * 24));

                this.loadedVideoIds.add(video.id);
                const videoData = {
                    ...video,
                    id: video.id,
                    views: this.formatViews(video.statistics.viewCount),
                    subscribers: this.formatViews(channelStats.subscriberCount),
                    viewsPerSubscriber: viewsPerSubscriber.toFixed(2),
                    viewDifference,
                    performanceMultiplier,
                    hype,
                    badge,
                    duration: durationFormatted,
                    daysSincePublished,
                    publishedAt,
                    cached: false // Indica se il risultato è cached
                };
                console.log('Video details processed:', videoData);
                return videoData;
            }));

            const filteredVideos = videosWithDetails.filter(video => video !== null);
            console.log('Filtered videos:', filteredVideos);
            this.videosData.push(...filteredVideos);

            // Aggiorna la cache con i nuovi risultati e nextPageToken
            this.videoCache[query] = {
                timestamp: Date.now(),
                videos: this.videosData,
                nextPageToken: this.nextPageToken // Salva anche il nextPageToken nella cache
            };
            localStorage.setItem('videoCache', JSON.stringify(this.videoCache));
            console.log('Cache updated for query:', query);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                console.error('API quota exceeded. Rotating API key.');
                this.rotateApiKey();
                this.fetchVideos(query, videoType);
            } else {
                console.error('Error fetching videos:', error);
            }
        }
    },

    loadMoreVideos() {
        console.log('Loading more videos for query:', this.query);
        this.fetchVideos(this.query, this.videoType);
    },
    clearCache() {
      this.videoCache = {};
      localStorage.removeItem('videoCache');
      console.log('Cache cleared');
    },
    loadSearchFromQuery(queryParams) {
      const { query, videoType, maxResults, showOnlyRecent } = queryParams;
      if (query) {
        this.query = query;
        this.videoType = videoType || 'long';
        this.maxResults = parseInt(maxResults) || 6;
        this.showOnlyRecent = showOnlyRecent === 'true';
        this.searchVideos(this.query, this.videoType);
      }
    },
    parseDuration(duration) {
      const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
      if (!match) return 0;

      const hours = parseInt(match[1]) || 0;
      const minutes = parseInt(match[2]) || 0;
      const seconds = parseInt(match[3]) || 0;
      return hours * 3600 + minutes * 60 + seconds;
    },
    formatDuration(seconds) {
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;
      return [hrs, mins, secs]
        .map(v => (v < 10 ? '0' + v : v))
        .filter((v, i) => v !== '00' || i > 0)
        .join(':');
    },
    formatViews(views) {
      const suffixes = ["", "K", "M", "B", "T"];
      let suffixNum = Math.floor(("" + views).length / 3);
      let shortValue = parseFloat((suffixNum !== 0 ? (views / Math.pow(1000, suffixNum)) : views).toFixed(1));

      if (shortValue < 1 && suffixNum > 0) {
        shortValue = parseFloat((views / Math.pow(1000, suffixNum - 1)).toFixed(1));
        suffixNum -= 1;
      }

      if (shortValue % 1 === 0) {
        shortValue = shortValue.toFixed(0);
      }

      return shortValue + suffixes[suffixNum];
    },

    getBadge(multiplier, viewsPerSubscriber) {
      const multiplierValue = parseFloat(multiplier);
      const viewsPerSubscriberValue = parseFloat(viewsPerSubscriber);
      let badge = '';
      if (multiplierValue > 2) {
        badge += '📈';
      }
      if (viewsPerSubscriberValue > 1.5) {
        badge += '🦤';
      }
      return badge;
    },
    rotateApiKey() {
      this.currentApiKeyIndex = (this.currentApiKeyIndex + 1) % this.apiKeys.length;
      console.log('API key rotated. Current key index:', this.currentApiKeyIndex);
    },
    handleUserLoggedIn(user) {
      this.user = user;
      console.log('User logged in:', user);
    },
    handleUserLoggedOut() {
      this.user = null;
      console.log('User logged out');
    },
    openVideo(videoId) {
      if (videoId) {
        console.log('Opening video with ID:', videoId);
        window.open(`https://www.youtube.com/watch?v=${videoId}`, '_blank');
      } else {
        console.error('Video ID is undefined');
      }
    },
    handleExactSearch(phrase) {
      console.log('Exact search triggered for phrase:', phrase);
      this.searchVideos(`"${phrase}"`);
    }
  }
};
</script>

<style scoped>
.badge {
  position: absolute;
  top: 10px;
  left: 10px;
}
.large-badge {
  font-size: 18px;
}
.badge-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
}
.dodo-video {
  border: 1px solid #ddd;
  padding: 16px;
  margin-bottom: 16px;
}
.development-message {
  background-color: red;
  color: yellow;
  font-weight: bold;
  margin-right: 20px;
}
</style>
