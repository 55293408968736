<template>
    <div>
        <br>
    <h2>Dodo Videos</h2>
    <br>
      <v-row v-for="video in videos" :key="video.id" class="dodo-video">
        <v-col cols="12" md="4">
          <v-img :src="video.snippet.thumbnails.medium.url" :alt="video.snippet.title" />
        </v-col>
        <v-col cols="12" md="8">
          <h3 v-html="highlightViralPhrases(video.snippet.title)" @click="searchExactMatch"></h3>
          <p>{{ video.snippet.channelTitle }} - Views: {{ video.views }}</p>
          <p>{{ video.publishedAt }} ({{ video.daysSincePublished }} days ago)</p>
          <v-chip v-if="video.badge" color="green" text-color="white">{{ video.badge }}</v-chip>
          <v-chip v-if="video.viralPattern" color="blue" text-color="white">{{ video.viralPattern }}</v-chip>
          <p>Views/Subs: {{ video.viewsPerSubscriber }}</p>
          <p>Multiplier: {{ video.performanceMultiplier }}</p>
          <p>Hype: {{ video.hype }}</p>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DodoVideos',
    props: {
      videos: Array
    },
    methods: {
      highlightViralPhrases(title) {
        const colors = ['red', 'green', 'blue', 'purple', 'orange'];
        let colorIndex = 0;
        let highlightedTitle = title;
  
        const words = title.split(' ');
        const phrases = this.getViralPhrases(words);
  
        phrases.forEach(phrase => {
          const regex = new RegExp(`(${phrase.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
          highlightedTitle = highlightedTitle.replace(regex, match => {
            const color = colors[colorIndex % colors.length];
            colorIndex++;
            return `<span style="color: ${color}; cursor: pointer;" data-phrase="${match}">${match}</span>`;
          });
        });
  
        return highlightedTitle;
      },
      getViralPhrases(words) {
        const phrases = [];
  
        for (let i = 0; i < words.length; i++) {
          for (let j = 3; j <= 5; j++) {
            if (i + j <= words.length) {
              const phrase = words.slice(i, i + j).join(' ');
              if (!phrases.includes(phrase)) {
                phrases.push(phrase);
              }
            }
          }
        }
  
        return phrases;
      },
      searchExactMatch(event) {
        const phrase = event.target.getAttribute('data-phrase');
        if (phrase) {
          this.$emit('exact-search', phrase);
        }
      },
      formatSubscribers(subscribers) {
        return new Intl.NumberFormat().format(subscribers);
      }
    }
  };
  </script>
  
  <style scoped>
  .dodo-video {
    border: 1px solid #ddd;
    padding: 16px;
    margin-bottom: 16px;
  }
  </style>
  