<template>
  <v-list v-if="videos.length">
    <v-list-item
      v-for="video in videos"
      :key="video.id"
      @click="() => openVideo(video.id)"
    >
      <v-row align="center">
        <v-col cols="auto">
          <v-img
            v-if="video.snippet && video.snippet.thumbnails && video.snippet.thumbnails.medium"
            :src="video.snippet.thumbnails.medium.url"
            :alt="video.snippet.title"
            width="200"
            height="120"
          >
            <!-- Badge overlay per la modalità lista -->
            <v-chip
              v-if="video.badge"
              class="badge-overlay"
              color="white"
              text-color="black"
              style="background-color: rgba(255, 255, 255, 0.8); font-size: 18px;"
            >
              {{ video.badge }}
            </v-chip>
            <!-- Durata del video in basso a destra -->
            <div class="video-duration">{{ video.duration }}</div>
          </v-img>
        </v-col>
        <v-col>
          <v-list-item-content>
            <v-list-item-title>{{ video.snippet?.title }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ video.snippet?.channelTitle }} ({{ video.subscribers }} subscribers) - Views: {{ video.views }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ video.publishedAt }} ({{ video.daysSincePublished }} days ago)
            </v-list-item-subtitle>
            <!-- Indicatore di cache -->
            <v-chip v-if="video.cached" color="green lighten-2">
              Cached
            </v-chip>
            <v-chip v-else color="red lighten-2">
              New
            </v-chip>
          </v-list-item-content>
          <br>
          <v-list-item-action>
            <v-chip color="blue lighten-2">
              Views/Subs: {{ video.viewsPerSubscriber }}
            </v-chip>
            <v-chip color="red lighten-2">
              Multiplier: {{ video.viewDifference }}
            </v-chip>
            <v-chip color="orange lighten-2">
              Hype: {{ video.hype }}
            </v-chip>
          </v-list-item-action>
        </v-col>
      </v-row>
      <br>
    </v-list-item>
  </v-list>
</template>

<script>
import axios from 'axios';

export default {
  name: 'VideoList',
  props: {
    videos: {
      type: Array,
      required: true
    }
  },
  methods: {
    async openVideo(videoId) {
      console.log('Opening video with ID:', videoId);
      if (videoId) {
        const isShort = await this.isYouTubeShort(videoId);
        const url = isShort
          ? `https://www.youtube.com/shorts/${videoId}`
          : `https://www.youtube.com/watch?v=${videoId}`;
        window.open(url, '_blank');
      } else {
        console.error('Video ID is undefined');
      }
    },
    async isYouTubeShort(videoId) {
      const url = `https://www.youtube.com/shorts/${videoId}`;
      try {
        const response = await axios.head(url);
        return response.status === 200; // Status 200 indicates it's a Short
      } catch (error) {
        if (error.response && error.response.status === 303) {
          return false; // Status 303 indicates a redirect to the standard watch URL
        }
        return false; // Default to false if any other error occurs
      }
    }
  },
  created() {
    console.log('VideoList videos:', this.videos);
  }
}
</script>

<style scoped>
.badge-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
}
.video-duration {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 2px 4px;
  border-radius: 2px;
  font-size: 14px;
}
</style>
