<template>
  <v-dialog v-model="internalDialog" max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">Search filters</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-range-slider
                v-model="internalFilters.multiplier"
                :min="1"
                :max="500"
                step="1"
                label="Multiplier"
                ticks="always"
                thumb-label
              ></v-range-slider>
              <span>{{ internalFilters.multiplier ? internalFilters.multiplier[0] : 0 }}x - {{ internalFilters.multiplier ? internalFilters.multiplier[1] : 0 }}x</span>
            </v-col>
            <v-col cols="6">
              <v-range-slider
                v-model="internalFilters.views"
                :min="1000"
                :max="1000000000"
                step="1000"
                label="Views"
                ticks="always"
                thumb-label
              ></v-range-slider>
              <span>{{ internalFilters.views ? formatNumber(internalFilters.views[0]) : 0 }} views - {{ internalFilters.views ? formatNumber(internalFilters.views[1]) : 0 }} views</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-range-slider
                v-model="internalFilters.subscribers"
                :min="900"
                :max="250000000"
                step="1000"
                label="Subscribers"
                ticks="always"
                thumb-label
              ></v-range-slider>
              <span>{{ internalFilters.subscribers ? formatNumber(internalFilters.subscribers[0]) : 0 }} subscribers - {{ internalFilters.subscribers ? formatNumber(internalFilters.subscribers[1]) : 0 }} subscribers</span>
            </v-col>
            <v-col cols="6">
              <v-range-slider
                v-model="internalFilters.duration"
                :min="60"
                :max="25200"
                step="60"
                label="Video duration"
                ticks="always"
                thumb-label
              ></v-range-slider>
              <span>{{ internalFilters.duration ? formatDuration(internalFilters.duration[0]) : '0h 0m' }} - {{ internalFilters.duration ? formatDuration(internalFilters.duration[1]) : '0h 0m' }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-range-slider
                v-model="internalFilters.viewsSubs"
                :min="0.1"
                :max="500"
                step="0.1"
                label="Views:Subs"
                ticks="always"
                thumb-label
              ></v-range-slider>
              <span>{{ internalFilters.viewsSubs ? internalFilters.viewsSubs[0] : 0 }}x - {{ internalFilters.viewsSubs ? internalFilters.viewsSubs[1] : 0 }}x</span>
            </v-col>
            <v-col cols="6">
              <v-range-slider
                v-model="internalFilters.comments"
                :min="1"
                :max="10000000"
                step="1"
                label="Comments"
                ticks="always"
                thumb-label
              ></v-range-slider>
              <span>{{ internalFilters.comments ? formatNumber(internalFilters.comments[0]) : 0 }} comments - {{ internalFilters.comments ? formatNumber(internalFilters.comments[1]) : 0 }} comments</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn-toggle v-model="internalFilters.publicationDate" mandatory>
                <v-btn value="All time">All time</v-btn>
                <v-btn value="Last week">Last week</v-btn>
                <v-btn value="Last month">Last month</v-btn>
                <v-btn value="Last 3 months">Last 3 months</v-btn>
                <v-btn value="Last 6 months">Last 6 months</v-btn>
                <v-btn value="Last year">Last year</v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="applyFilters">Apply changes</v-btn>
        <v-btn color="grey" text @click="closeDialog">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
      default: () => ({
        multiplier: [1, 500],
        views: [1000, 1000000000],
        subscribers: [900, 250000000],
        duration: [60, 25200],
        viewsSubs: [0.1, 500],
        comments: [1, 10000000],
        publicationDate: 'All time',
      }),
    },
  },
  data() {
    return {
      internalDialog: this.dialog,
      internalFilters: { ...this.filters },
    };
  },
  watch: {
    dialog(val) {
      this.internalDialog = val;
    },
    internalDialog(val) {
      this.$emit('update:dialog', val);
    },
    filters: {
      handler(val) {
        this.internalFilters = { ...val };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    formatNumber(number) {
      return number ? number.toLocaleString() : '0';
    },
    formatDuration(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      return `${hours}h ${minutes}m`;
    },
    closeDialog() {
      this.$emit('update:dialog', false);
    },
    applyFilters() {
      this.$emit('update:filters', { ...this.internalFilters });
      this.$emit('apply-filters', { ...this.internalFilters }); // Emissione evento per attivare la ricerca
      this.closeDialog();
    },
  },
};
</script>

<style scoped>
.headline {
  font-weight: bold;
}
</style>
